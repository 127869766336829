import React from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import DefaultAuthenticationFallback from './components/LoginPage';
import UserAdminService from './services/UserAdminService';
import { firebaseApp, firestoreDb, firebaseAuth, firebaseConfig, serverTimestamp, firebaseStorage } from './firebase';

import { version } from '../package.json';
import ProjectLineItemService from './services/ProjectLineItemService';
import ProjectService from './services/ProjectService';
import VendorBillService from './services/VendorBillService';
import BillingReportService from './services/BillingReportService';
import ClientService from './services/ClientService';

//register UIkit Icons globally so they can be used throughout the site
UIkit.use(Icons);

//use App as the root for global state
export const ApplicationContext = React.createContext(null);

//HOC to make consuming the global state easier
export const withContext = (Component) => (props) => (
    <ApplicationContext.Consumer>
        {context => <Component context={context} {...props} />}
    </ApplicationContext.Consumer>
);

//HOC to switch between two components based on authentication state, as well as pass context
export const withSecureContext =
    (Component, Fallback = DefaultAuthenticationFallback) => withContext((props) => (
        props.context.isAuthenticated ? <Component {...props} /> : <Fallback {...props} />
    ));

function calculateEnv() {
    const projectId = firebaseConfig.projectId.toLowerCase();
    if (projectId.includes('dev')) {
        return 'DEV';
    } else if (projectId.includes('qa')) {
        return 'QA';
    } else if (projectId.includes('uat')) {
        return 'UAT';
    } else {
        return 'PROD';
    }
}

//default firebase context, generally suitable for all applications
const defaultFirebaseAppContext = ({ history }) => ({
    //firebase application
    firebase: firebaseApp,
    site: `https://${firebaseConfig.authDomain}`,
    version: version,
    env: calculateEnv(),
    //firebase auth
    isAuthenticated: false,
    authUser: null,
    signOut: () => firebaseAuth().signOut(),
    //firestore db
    db: firestoreDb,
    storage: firebaseStorage,
    timestamp: serverTimestamp(),
    services: {},
    //route manipulation
    changeRoute: (newRoute) => { history.push(newRoute); },
    goBack: () => { history.goBack(); },
    //ui manipulation
    modal: UIkit.modal,
    //uikit notifications default to bottom-center
    notification: (msg, opts) => UIkit.notification(msg, { pos: 'bottom-center', ...opts }),
    closeAllNotifications: UIkit.notification.closeAll,
    offcanvas: UIkit.offcanvas,
    util: UIkit.util,
    toggle: UIkit.toggle,
    //error handling stub
    error: console.error
});

//application specific contexts
export const initializeContext = ({ history }) => {
    let context = defaultFirebaseAppContext({ history });

    //initialize services
    const services = {
        userAdmin: new UserAdminService({ siteUrl: context.site }),
        client: new ClientService({ db: firestoreDb }),
        projectLineItem: new ProjectLineItemService({ db: firestoreDb }),
        project: new ProjectService({ db: firestoreDb }),
        vendorBill: new VendorBillService({ db: firestoreDb }),
        billingReport: new BillingReportService({ db: firestoreDb }),
    };
    //inject some higher level functions
    const servicesObject = Object.freeze({
        ...services,
        authenticateUser(user, idToken) {
            Object.values(services).forEach(service => {
                service.authenticateUser(user, idToken);
            });
        },
        logoutUser() {
            Object.values(services).forEach(service => {
                service.logoutUser();
            });
        }
    });
    //add services to context
    context = { ...services, ...context, services: servicesObject };

    //personalization
    context.isLoaded = false;
    context.darkTheme = false;
    context.recaptchaSiteKey = firebaseConfig.recaptchaSiteKey;
    return context;
};
