const { default: FirestoreCollectionService } = require("./Service");

class VendorBillService extends FirestoreCollectionService {
    constructor({ db }) {
        super({
            db,
            collectionName: 'vendorBill',
            objectSortColumn: 'end',
            objectSortDirection: 'desc'
        });
    }
}

export default VendorBillService;