import firebase from 'firebase/app';
import React, { PureComponent } from 'react';
import { LabeledControl } from './Util';
import { auth_labels } from '../resources/labels';

const iconStyle = { width: '1.1em', marginRight: '1em', marginBottom: '0.3em' };

const GoogleIcon = () => (
  <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg" style={iconStyle}>
    <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4" />
    <path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853" />
    <path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04" />
    <path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335" />
  </svg>
);

const googleProvider = new firebase.auth.GoogleAuthProvider();
//const oauthProvider = new firebase.auth.OAuthProvider();
//const emailProvider = new firebase.auth.EmailAuthProvider();
export class GoogleLogin extends PureComponent {
  googleOCL = async (e) => {
    const { context, location } = this.props;
    const { notification, changeRoute, error } = context;
    try {
      /* const res = */ await firebase.auth().signInWithPopup(googleProvider);
      // const token = res.credential.accessToken;
      // const user = res.user;
      notification('Login Successful', { status: 'success' });
      if (location.pathname === '/login' || location.pathname === '/signUp') {
        changeRoute('/');
      }
    } catch ({ code }) {
      if (code !== 'auth/popup-closed-by-user') {
        notification('Error Occurred during Google Login', { status: 'danger' });
        error(code);
      }
    }
  };
  render() {
    const { buttonText = 'Sign in with Google' } = this.props;
    return (
      <button onClick={this.googleOCL} className='uk-button uk-button-default' style={{ textTransform: 'none', height: '3em' }}>
        <GoogleIcon />
        {buttonText}
      </button>
    );
  }
}

class LoginPage extends PureComponent {
  email_input_ref = React.createRef();
  password_input_ref = React.createRef();
  rem_device_input_ref = React.createRef();
  submitLoginForm = async (e) => {
    e.preventDefault();
    const { context, location } = this.props;
    const { notification, changeRoute, error } = context;
    const email = this.email_input_ref.current.value;
    const password = this.password_input_ref.current.value;
    const rem_device = this.rem_device_input_ref.current.checked;
    if (email && password) {
      try {
        if (!rem_device) {
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        }
        await firebase.auth().signInWithEmailAndPassword(email, password);
        notification('Login Successful', { status: 'success' });
        if (location.pathname === '/login') {
          changeRoute('/');
        }
      } catch ({ code }) {
        if (code === "auth/user-not-found" || code === "auth/wrong-password") {
          notification(auth_labels.login_error_credentials, { status: 'danger' });
        } else {
          notification(auth_labels.login_error_unknown, { status: 'danger' });
        }
        error(code);
      }
    }
  };
  render() {
    return (
      <div className='uk-section uk-padding-remove-vertical uk-animation-slide-top-small'>
        <div className='uk-container uk-container-xsmall uk-background-default uk-padding'>
          <h2 className='uk-text-center'>Login</h2>
          <form className='uk-form-horizontal' onSubmit={this.submitLoginForm}>
            <LabeledControl id='email_input' label='Email' required={true} className='uk-margin-bottom'>
              <input className='uk-input' type='email' ref={this.email_input_ref} />
            </LabeledControl>
            <LabeledControl id='password_input' label='Password' required={true} className='uk-margin-bottom'>
              <input className='uk-input' type='password' ref={this.password_input_ref} />
            </LabeledControl>
            <LabeledControl id='rem_device_input' label={auth_labels.rem_device} className='uk-margin-bottom'>
              <input className='uk-checkbox' type='checkbox' ref={this.rem_device_input_ref} />
            </LabeledControl>
            <div className='uk-text-center'>
              <input type='submit' value={auth_labels.login_button} className='uk-button uk-button-default' />
            </div>
          </form>
          <hr />
          <div className='uk-text-center uk-margin-top'>
            <GoogleLogin location={this.props.location} context={this.props.context} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
