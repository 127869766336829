import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { withContext } from '../appState';
import { nav_links } from '../resources/labels';
import logoBulb from '../resources/logos/logo_bulb.svg';

class NavbarOffcanvas extends PureComponent {
  componentDidUpdate(prevProps) {
    //hide
    if(prevProps.pathname!==this.props.pathname) {
      this.props.context.offcanvas(`#${this.props.id}`).hide();
    }
  }
  render() {
    const { context, id } = this.props;
    return (
      <div id={id} uk-offcanvas="overlay: true;">
        <div className="uk-offcanvas-bar">
          <button className="uk-offcanvas-close" type="button" title="Close Menu" uk-close="true"/>
          <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
            {nav_links
                .filter(({adminOnly, signedIn})=>{
                  let show = true;
                  if(signedIn===true) show &= context.isAuthenticated;
                  if(signedIn===false) show &= !context.isAuthenticated;
                  if(adminOnly===true) show &= context.isAdmin;
                  return show;
                })
                .map(({label, link},link_i)=>(
                  <li key={`navlink_${label}`}><Link to={link}>{label}</Link></li>
                ))}
          </ul>
          <hr/>
          {context.isAuthenticated ? <>
            <ul className='uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical'>
              <li><Link to={`/user`}>{(context.authUser && context.authUser.displayName) || 'User'}</Link></li>
              <li><Link to={`/logout`}>Logout</Link></li>
            </ul>
          </> : <>
            <ul className='uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical'>
                <li><Link to={`/login`}>Login</Link></li>
              </ul>
          </>}
        </div>
      </div>
    );
  }
}

class Navbar extends PureComponent {
  showOffcanvas = () => {
    this.props.context.offcanvas('#navbar-offcanvas').show();
  }
  render() {
    const { context, location } = this.props;
    return <>
      <nav className="uk-navbar-container uk-navbar-transparent" uk-navbar="true">
        <div className="uk-navbar-left">
          <button className="uk-navbar-toggle" uk-navbar-toggle-icon="true" 
            onClick={this.showOffcanvas} title="Show Menu"/>
        </div>
        <div className="uk-navbar-center">
          <Link className="uk-navbar-item uk-logo" to="/">
            <img src={logoBulb} style={{height:'32px', marginRight:'6px'}} alt='HGS Logo'/>
            Admin
          </Link>
        </div>
      </nav>
      <NavbarOffcanvas id="navbar-offcanvas" context={context} pathname={location.pathname}/>
    </>;
  }
}

export default withContext(Navbar);
