
export const auth_labels = {
  email: "Email",
  password: "Password",
  rem_device: "Keep me logged in",
  confirm_rem_device: `"Keep me logged in" should never be used on public devices. Your session will not end until you click "Logout".`,
  login_button: "Login",
  login_success: "Login Successful",
  login_error_credentials: "Check your credentials and try again",
  login_error_unknown: "An unknown error has occured while logging in"
};

export const global_labels = {
  title: 'HGS Admin',
  company: 'HomeGrown Solutions'
};

export const nav_links = [
  { label: 'Home', link: '/' },
  { label: 'Projects', link: '/projects', signedIn: true },
  { label: 'Admin', link: '/admin', adminOnly: true, signedIn: true },
];