const { default: FirestoreCollectionService } = require("./Service");

class ClientService extends FirestoreCollectionService {
    constructor({ db }) {
        super({
            db,
            collectionName: 'client',
            objectSortColumn: 'createdOn',
            objectSortDirection: 'desc'
        });
    }

    objectCollection = (view) => {
        let collectionRef = this.db.collection(this.collectionName);
        if (view === 'managedByMe' && this.authUser) {
            return collectionRef
                .where('managedBy', 'array-contains', this.authUser.uid);
        }
        return collectionRef;
    };
}

export default ClientService;