const { default: FirestoreCollectionService } = require("./Service");

class ProjectLineItemService extends FirestoreCollectionService {
    constructor({ db }) {
        super({
            db,
            collectionName: 'projectLineItem',
            objectSortColumn: 'createdOn',
            objectSortDirection: 'desc'
        });
    }
}

export default ProjectLineItemService;