import React, { PureComponent, Suspense, useEffect, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter, useHistory } from 'react-router-dom';

import { firebaseApp, firebaseAnalytics } from './firebase';
import { initializeContext, withContext, ApplicationContext } from './appState';
import Navbar from './components/Navbar';
import LoginPage from './components/LoginPage';
import { global_labels } from './resources/labels';

import './App.scss';

const UserPageLazy = lazy(() => import('./pages/user/UserPage'));
const UserPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <UserPageLazy {...props} />
  </Suspense>
));

const AdminPageLazy = lazy(() => import('./pages/admin/AdminPage'));
const AdminPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <AdminPageLazy {...props} />
  </Suspense>
));

const BillingPageLazy = lazy(() => import('./pages/BillingPage'));
const BillingPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <BillingPageLazy {...props} />
  </Suspense>
));

const ProjectsPageLazy = lazy(() => import('./pages/ProjectsPage'));
const ProjectsPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <ProjectsPageLazy {...props} />
  </Suspense>
));


const Login = withContext(LoginPage);

const Logout = withContext(({ context }) => {
  context.signOut().then(() => {
    context.notification('Logout Successful');
  });
  return <Redirect to="/" />;
});

const Landing = withContext(({ context }) => {
  return (
    <div className='uk-section uk-animation-slide-top-small'>
      <div className='uk-container uk-container-xsmall uk-text-center'>
        {context.isAuthenticated ? <>
          <div className='uk-card uk-card-body uk-card-large uk-card-secondary'>
            <h2 className='uk-card-title'>Welcome</h2>
            <p>This site is for internal project management. If you reached this site in error, please use the back button on your browser to go back. Only HomeGrown Solutions and its affiliates are permitted to use this site.</p>
          </div>
        </> : (
            <div className='uk-card uk-card-body uk-card-large uk-card-primary'>
              <h2 className='uk-card-title'>Authenticated Access Only</h2>
              <p>This site is for internal project management. If you reached this site in error, please use the back button on your browser to go back. Only HomeGrown Solutions and its affiliates are permitted to use this site.</p>
            </div>
          )}
      </div>
    </div>
  );
});

const Error404 = withContext(({ context }) => {
  return (
    <div className='uk-section uk-animation-slide-top-small'>
      <div className='uk-container uk-container-small uk-text-center'>
        <h2 className='uk-heading-small'>Page Not Found</h2>
        <p className='uk-text-lead'>The requested page could not be found.</p>
      </div>
    </div>
  );
});

const Loading = withContext(({ context }) => {
  return (
    <div className='uk-section uk-animation-slide-top-small'>
      <div className='uk-container uk-container-small uk-text-center'>
        <h2 className='uk-heading-small'>Loading...</h2>
        <p className='uk-text-lead'>The requested page is being loaded.</p>
      </div>
    </div>
  );
});

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initializeContext({ history: props.history });
  }
  async componentDidMount() {
    //configure firebase
    const runningOnIE11 = !!window.document.documentMode;
    if (runningOnIE11) {
      this.state.notification('This site works best on a more modern browser.');
      //this.state.error("Offline data persistence is unavailable on this browser.");
    } else {
      try {
        //allow multiple user sessions in the same browser to share memory
        await this.state.db.enablePersistence({ synchronizeTabs: true });
      } catch (err) {
        if (err.code === 'unimplemented') {
          this.state.error("Offline data persistence is unavailable on this browser.");
        }
      }
    }
    //configure firebase auth
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const tokenResult = await firebaseApp.auth().currentUser.getIdTokenResult();
        if (this.state.services) {
          this.state.services.authenticateUser(user, tokenResult.token);
        } else {
          console.warn('Attempted login, but no User Administration Service is registered.');
        }
        this.setState(() => ({
          authUser: { ...user, claims: tokenResult.claims },
          isAuthenticated: true,
          isAdmin: tokenResult.claims.admin,
          isLoaded: true
        }), () => {
          this.validateUserDetails(user);
        });
      } else if (this.state.isAuthenticated) {
        if (this.state.services) {
          this.state.services.logoutUser();
        } else {
          console.warn('Attempted logout, but no User Administration Service is registered.');
        }
        this.setState(() => ({ authUser: null, isAuthenticated: false, isLoaded: true }));
      } else {
        this.setState(() => ({ isLoaded: true }));
      }
    });
  }
  validateUserDetails = async (user) => {
    //users must have a display name
    if (user) {
      if (!user.displayName) {
        window.alert("You have not yet set a display name for this account. You will be redirected to your Profile. Please set a display name now.");
        this.state.changeRoute('/user/profile');
      }
      if (!user.emailVerified) {
        window.alert("You have not yet verified your email for this account. You will be redirected to your Profile. Please verify your email before continuing.");
        this.state.changeRoute('/user/profile');
      }
    }
  }
  render() {
    const ukBackground = this.state.darkTheme ? "uk-background-secondary" : "uk-background-muted";
    const ukTheme = this.state.darkTheme ? "uk-light" : "uk-dark";
    const CatchAllComponent = !this.state.isLoaded ? Loading : Error404;
    const appOpacity = this.state.isLoaded ? 1 : 0;
    return (
      <div className={`AppContainer ${ukBackground} ${ukTheme}`}>
        <ApplicationContext.Provider value={this.state}>
          <Route path="/**" component={Navbar} />
          <main className="App" style={{ opacity: appOpacity }}>
            {this.state.isLoaded ? (
              <Suspense fallback={<Loading />}>
                <Switch location={this.props.location}>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/" component={Landing} />
                  <Route path="/user" component={UserPage} />
                  <Route path="/admin" component={AdminPage} />
                  <Route path="/projects/billing" component={BillingPage} />
                  <Route path="/projects" component={ProjectsPage} />
                  <Route path="/**" component={CatchAllComponent} />
                </Switch>
              </Suspense>
            ) : (
                <Loading />
              )}
          </main>
          <footer className='uk-margin-large-top'>
            <div className='uk-text-center uk-margin-top uk-margin-large-bottom'>
              <p className='uk-text-small'>Copyright 2020 {global_labels.company}</p>
            </div>
            <div className='AppFooter'>
              <div className="uk-label uk-label-default">
                {this.rebuildAlgoliaIndexesOCL && this.state.isAdmin &&
                  <button className={`uk-margin-small-right uk-button uk-button-link`}
                    uk-tooltip={'Rebuild Search Index'} uk-icon='icon: database;'
                    onClick={this.rebuildAlgoliaIndexesOCL} />}
                <span className='uk-text-uppercase'>{this.state.env} - {this.state.version}</span>
              </div>
            </div>

          </footer>
        </ApplicationContext.Provider>
      </div>
    );
  }
}

const AppWithRouter = withRouter(App);

//listen to history changes and log them in Firebase Analytics
const logCurrentPage = () => {
  firebaseAnalytics.setCurrentScreen(window.location.pathname);
  firebaseAnalytics.logEvent('screen_view');
};
const AnalyticsListener = () => {
  const history = useHistory();
  useEffect(() => {
    logCurrentPage();
    history.listen(logCurrentPage);
  }, [history]);
  return <></>;
};

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);
  return null;
});

const RoutedApp = () => (
  <BrowserRouter>
    <AppWithRouter />
    <ScrollToTop />
    <AnalyticsListener />
  </BrowserRouter>
);

export default RoutedApp;
