import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/analytics';

export const firebaseConfig = {
    apiKey: "AIzaSyB8y2taqQHOJa9lUSSl6sTpXCdF7-mAPNs",
    authDomain: "hgs-internal.firebaseapp.com",
    databaseURL: "https://hgs-internal.firebaseio.com",
    projectId: "hgs-internal",
    storageBucket: "hgs-internal.appspot.com",
    messagingSenderId: "989320535409",
    appId: "1:989320535409:web:2e11567b913833c5e0ead0",
    measurementId: "G-14CSG7781D",
    recaptchaSiteKey: "6Ld4vboZAAAAABbXyhUCHV9t5LP9WXOh7oQjp6Kz"
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreDb = firebaseApp.firestore();
export const firebasePerformance = firebaseApp.performance();
export const firebaseAnalytics = firebaseApp.analytics();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const timestamp = firebase.firestore.Timestamp;